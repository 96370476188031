<script setup lang="ts">
import { loadScript } from "vue-plugin-load-script"
import NavigationBar from "~/components/NavigationBar.vue"
import MemodioFooter from "~/components/MemodioFooter.vue"
import CookieNotice from "~/components/CookieNotice.vue"
import CookiePreferences from "~/components/CookiePreferences.vue"
import { wasAnalyticsAccepted } from "~/composables/useAcceptanceCookie.ts"

const { t } = useI18n()
const title = computed(() => t("general.seoTitle"))
const description = computed(() => t("general.seoDescription"))
const fallbackImageUrl = "/images/blog/open_graph_preview.png"

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
})

useHead({
  meta: [{ name: "description", content: description }],
  link: [{ rel: "icon", type: "image/png", href: "/favicon.svg" }],
})

// Load Google Analytics
if (wasAnalyticsAccepted()) {
  loadScript("https://www.googletagmanager.com/gtag/js?id=G-W7HYCR3N9Q")
  loadScript("/scripts/gtag.js")
}
</script>
<template>
  <div>
    <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
      <Head>
        <Title>{{ title }}</Title>
        <Meta name="format-detection" content="telephone-no" />
        <Meta property="og:title" :content="title" />
        <Meta property="og:description" :content="description" />
        <Meta property="og:type" content="website" />
        <Meta property="og:image" :content="fallbackImageUrl" />

        <Link
          v-for="link in head.link"
          :id="link.id"
          :key="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />

        <Meta
          v-for="meta in head.meta"
          :id="meta.id"
          :key="meta.id"
          :property="meta.property"
          :content="meta.content"
        />
      </Head>
      <Body>
        <div class="page-content">
          <NavigationBar />
          <div id="main-view">
            <slot />
          </div>
        </div>
        <MemodioFooter />
        <CookieNotice />
        <CookiePreferences />
      </Body>
    </Html>
  </div>
</template>

<script lang="ts">
export default {
  name: "DefaultLayout",
  data() {
    return {
      analyticsCookie: false,
    }
  },
  mounted() {
    this.analyticsCookie = this.isAnalyticsCookieActive()
  },
  methods: {
    isAnalyticsCookieActive() {
      const acceptanceCookieJson = localStorage.getItem("cookieAcceptance")
      if (acceptanceCookieJson) {
        const acceptanceCookie = JSON.parse(acceptanceCookieJson)
        const cookieName = acceptanceCookie.name
        if (cookieName === "all" || cookieName === "statistics") {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@use "~/assets/css/main.scss";

@font-face {
  font-family: "Spline Sans";
  src: url(../assets/fonts/Spline_Sans-300-latin2.woff2) format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Spline Sans";
  src: url(../assets/fonts/Spline_Sans-500-latin6.woff2) format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Spline Sans";
  src: url(../assets/fonts/Spline_Sans-600-latin8.woff2) format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Spline Sans";
  src: url(../assets/fonts/Spline_Sans-400-latin4.woff2) format("woff");
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.2em;
}
h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2em;
}
</style>
